// React
import React, { FC, lazy, useMemo } from 'react';
// Material-UI
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import InsertChartOutlinedOutlinedIcon from '@mui/icons-material/InsertChartOutlinedOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GrassOutlinedIcon from '@mui/icons-material/GrassOutlined';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
//import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
// Otras libs

// Componentes
import { Navegacion } from 'components/Navegacion';
import { Position } from 'components/Navegacion/IMenuNavProps';
// Context
import TokenService from 'lib/TokenService';
// Assets
//import logo from './logo.svg';
import './App.css';
import { LoginService } from 'services/LoginService';
import useAuth from 'hooks/useAuth';
import { CustomFooter } from 'components/CustomFooter';
import MyPage from 'pages/MyPage';
import useRoles from 'hooks/useRoles';
// Paginas públicas
const HomePage = lazy(() => import('pages/HomePage'));
const LoginPage = lazy(() => import('pages/LoginPage'));
const LogoutPage = lazy(() => import('pages/LogoutPage'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const InvestigadoresPage = lazy(() => import('pages/InvestigadoresPage'));
const PublicacionesPage = lazy(() => import('pages/PublicacionesPage'));
const ContactoPage = lazy(() => import('pages/ContactoPage'));
const MetabolómicaPage = lazy(() => import('pages/MetabolomicaPage'));
const BuscadorPage = lazy(() => import('pages/BuscadorPage'));
const GeneradorConsorciosPage = lazy(() => import('pages/GeneradorConsorciosPage'));
const BuscadorBasicoResultadoPage = lazy(() => import('pages/BuscadorBasicoResultadoPage'));
const AreaEstudioPage = lazy(() => import('pages/AreaEstudioPage'));
const MicroorganismosPage = lazy(() => import('pages/MicroorganismosPage'));
const Metagenomica16SPage = lazy(() => import('pages/Metagenomica16SPage'));
const MetagenomicaShotgunPage = lazy(() => import('pages/MetagenomicaShotgunPage'));
//const ConsorciosPage = lazy(() => import('pages/ConsorciosPage'));
const PlantasEstudiadasPage = lazy(() => import('pages/PlantasEstudiadasPage'));
// Páginas privadas
const GestorUsuariosPage = lazy(() => import('pages/GestorUsuariosPage'));
const MantenedorUsuariosPage = lazy(() => import('pages/MantenedorUsuariosPage'));
const MantenedorRolesPage = lazy(() => import('pages/MantenedorRolesPage'));
const MantenedorInstitucionesPage = lazy(() => import('pages/MantenedorIntitucionesPage'));
const GestorEspeciesVegetalesPage = lazy(() => import('pages/GestorEspeciesVegetalesPage'));
const MantenedorGenerosPage = lazy(() => import('pages/MantenedorGenerosPage'));
const MantenedorEspeciesPage = lazy(() => import('pages/MantenedorEspeciesPage'));
const MantenedorPlantasPage = lazy(() => import('pages/MantenedorPlantasPage'));
const MantenedorGramPage = lazy(() => import('pages/MantenedorGramPage'));
const MantenedorMorfologiasPage = lazy(() => import('pages/MantenedorMorfologiasPage'));
const MantenedorFormasPage = lazy(() => import('pages/MantenedorFormasPage'));
const MantenedorElevacionesPage = lazy(() => import('pages/MantenedorElevacionesPage'));
const MantenedorSuperficiesPage = lazy(() => import('pages/MantenedorSuperficiesPage'));
const MantenedorLocalidadesPage = lazy(() => import('pages/MantenedorLocalidadesPage'));
const MantenedorMediosCultivosPage = lazy(() => import('pages/MantenedorMediosCultivosPage'));
const MantenedorTiposSecPage = lazy(() => import('pages/MantenedorTiposSecPage'));
const GestorAisladosPage = lazy(() => import('pages/GestorAisladosPage'));
const GestorPublicacionesPage = lazy(() => import('pages/GestorPublicacionesPage'));
const PublicacionPage = lazy(() => import('pages/PublicacionPage'));


const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'Montserrat',
      'Kumbh Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: 'Kumbh Sans',
      fontWeight: 900,
    },
    h2: {
      fontFamily: 'Kumbh Sans',
      fontWeight: 900,
    },
    h3: {
      fontFamily: 'Kumbh Sans',
      fontWeight: 900,
    },
    h4: {
      fontFamily: 'Kumbh Sans',
      fontWeight: 900,
    },
    h5: {
      fontFamily: 'Kumbh Sans',
      fontWeight: 900,
    },
    h6: {
      fontFamily: 'Kumbh Sans',
      fontWeight: 900,
    },
    body1: {
      fontFamily: 'Kumbh Sans',
    },
    body2: {
      fontFamily: 'Kumbh Sans',
    },
    subtitle1: {
      fontFamily: 'Kumbh Sans',
    },
    subtitle2: {
      fontFamily: 'Kumbh Sans',
    },

  },
  palette: {
    background: {
      default: '#FCFCFD',
    },
    primary: {
      main: '#f7931e',
      contrastText: '#fff', 
    }
  },
});

interface AppProps {
  appName: string,
  version: string,
}

const App: FC<AppProps> = ({ appName, version }): JSX.Element => {
  const [iniciaApp, setIniciaApp] = React.useState(false);
  const auth = useAuth();
  const { accesos } = useRoles();

  React.useEffect(() => {
    const iniciarApp = async () => {
      // Titulo app
      document.title = appName;
      // Obtener usuario actual
      const res = await LoginService.getCurrentUser();
      if (res.data && !res.error) {
        auth.signin({
          nombres: res.data.nombres.split(" ")[0] + " " + res.data.paterno,
          email: res.data.email,
          token: res.data.token,
          refreshToken: res.data.refreshToken,
          roles: [],
        });
      } else {
        TokenService.removeTokens();
      }

      await new Promise(resolve => setTimeout(resolve, 500));
      setIniciaApp(true);
    };

    if (!iniciaApp) iniciarApp();
  }, [appName, auth, iniciaApp]);

  const rutasPublicas = useMemo(() => [
    {
      groupTitle: "",
      position: Position.Top,
      options: []
    },
    {
      groupTitle: "",
      position: Position.Left,
      options: [
        {
          title: "Inicio",
          icon: HomeOutlinedIcon,
          link: "/",
          component: <HomePage />,
          protected: false,
          subOptions: [],
        },
        {
          title: "Buscador",
          icon: SearchOutlinedIcon,
          link: "/buscador",
          component: <BuscadorPage />,
          protected: false,
          subOptions: [
            {
              title: "Buscador",
              icon: BubbleChartOutlinedIcon,
              link: "/buscador/especies/:especieId",
              component: <BuscadorBasicoResultadoPage />,
              protected: false,
            },
            {
              title: "Buscador Avanzado",
              icon: BubbleChartOutlinedIcon,
              link: "/buscadorAvanzado/",
              component: <BuscadorPage />,
              protected: false,
            },
          ],
        },
        {
          title: "Investigadores",
          icon: BadgeOutlinedIcon,
          link: "/investigadores",
          component: <InvestigadoresPage />,
          protected: false,
          subOptions: [],
        }, 
        {
          title: "Áreas de estudio",
          icon: PinDropOutlinedIcon,
          link: "/areaEstudio",
          component: <AreaEstudioPage />,
          protected: false,
          subOptions: [],
        },
        {
          title: "Especies Vegetales",
          icon: GrassOutlinedIcon,
          link: "/especiesVegetales",
          component: <PlantasEstudiadasPage />,
          protected: false,
          subOptions: [],
        },
      ]
    },
    {
      groupTitle: "Rizosfera",
      groupIcon: AccountTreeOutlinedIcon,
      position: Position.Left,
      options: [
        {
          title: "Microorganismos aislados",
          icon: BubbleChartOutlinedIcon,
          link: "/microorganismos",
          component: <MicroorganismosPage />,
          protected: false,
          subOptions: [],
        },
        {
          title: "Metagenómica 16S RNA",
          icon: TimelineOutlinedIcon,
          link: "/metagenomica",
          component: <Metagenomica16SPage />,
          protected: false,
          subOptions: [],
        },
        {
          title: "Metagenómica",
          icon: TimelineOutlinedIcon,
          link: "/mShotgun",
          component: <MetagenomicaShotgunPage />,
          protected: false,
          subOptions: [],
        },/*
        {
          title: "Consorcios",
          icon: SchemaOutlinedIcon,
          link: "/consorcios",
          component: <ConsorciosPage />,
          protected: false,
          subOptions: [],
        },*/
        {
          title: "Metabolómica",
          icon: InsertChartOutlinedOutlinedIcon,
          link: "/metabolomica",
          component: <MetabolómicaPage />,
          protected: false,
          subOptions: [],
        },
      ]
    },
    {
      groupTitle: "",
      position: Position.Left,
      options: [
        {
          title: "Generador de consorcios",
          icon: GroupWorkIcon,
          link: "/generadorConsorcios",
          component: <GeneradorConsorciosPage/>,
          protected: false,
          subOptions: [],
        },
        {
          title: "Publicaciones",
          icon: ArticleOutlinedIcon,
          link: "/publicaciones",
          component: <PublicacionesPage />,
          protected: false,
          subOptions: [
            {
              title: "Publicación",
              icon: ArticleOutlinedIcon,
              link: "/publicaciones/:publicacionId",
              component: <PublicacionPage />,
              protected: false,
            },
          ],
        },
        {
          title: "Contacto",
          icon: EmailOutlinedIcon,
          link: "/contacto",
          component: <ContactoPage />,
          protected: false,
          subOptions: [],
        },
      ]
    },
  ], []);

  const rutasPrivadas = useMemo(() => [
    {
      groupTitle: "",
      position: Position.Top,
      options: []
    },
    {
      groupTitle: "",
      position: Position.Left,
      options: [
        {
          title: "Inicio",
          icon: HomeOutlinedIcon,
          link: "/",
          component: <MyPage />,
          protected: true,
          subOptions: [],
        },
        {
          title: "Gestor de usuarios",
          icon: GroupAddOutlinedIcon,
          link: "/gestor-usuarios",
          component: <GestorUsuariosPage />,
          protected: true,
          hide: !accesos.some(a => a.name === 'USR' || a.name === 'ROL' || a.name === 'INS'),
          subOptions: [
            {
              title: "Gestor de usuarios",
              icon: BubbleChartOutlinedIcon,
              link: "/gestor-usuarios/usuarios/paginador",
              component: <MantenedorUsuariosPage />,
              protected: true,
            },
            {
              title: "Gestor de roles",
              icon: BubbleChartOutlinedIcon,
              link: "/gestor-usuarios/roles/paginador",
              component: <MantenedorRolesPage />,
              protected: true,
            },
            {
              title: "Instituciones",
              icon: BubbleChartOutlinedIcon,
              link: "/gestor-usuarios/instituciones/paginador",
              component: <MantenedorInstitucionesPage />,
              protected: true,
            },
          ],
        },
        {
          title: "Especies vegetales",
          icon: GrassOutlinedIcon,
          link: "/especies-vegetales",
          component: <GestorEspeciesVegetalesPage />,
          protected: true,
          hide: !accesos.some(a => a.name === 'GEN' || a.name === 'ESP' || a.name === 'PLA' || a.name === 'GRM' || a.name === 'MRF' || a.name === 'FRM' || a.name === 'ELV' || a.name === 'SUP' || a.name === 'LOC' || a.name === 'MDC' || a.name === 'TSC'),
          subOptions: [
            {
              title: "Géneros",
              icon: BubbleChartOutlinedIcon,
              link: "/especies-vegetales/generos/paginador",
              component: <MantenedorGenerosPage />,
              protected: true,
              hide: !accesos.some(a => a.name === 'GEN'),
              subOptions: [],
            },
            {
              title: "Especies",
              icon: BubbleChartOutlinedIcon,
              link: "/especies-vegetales/especies/paginador",
              component: <MantenedorEspeciesPage />,
              protected: true,
              hide: !accesos.some(a => a.name === 'ESP'),
              subOptions: [],
            },
            {
              title: "Plantas",
              icon: BubbleChartOutlinedIcon,
              link: "/especies-vegetales/plantas/paginador",
              component: <MantenedorPlantasPage />,
              protected: true,
              hide: !accesos.some(a => a.name === 'PLA'),
              subOptions: [],
            },
            {
              title: "Tinción de Gram",
              icon: BubbleChartOutlinedIcon,
              link: "/especies-vegetales/gram/paginador",
              component: <MantenedorGramPage />,
              protected: true,
              hide: !accesos.some(a => a.name === 'GRM'),
              subOptions: [],
            },
            {
              title: "Morfologías",
              icon: BubbleChartOutlinedIcon,
              link: "/especies-vegetales/morfologias/paginador",
              component: <MantenedorMorfologiasPage />,
              protected: true,
              hide: !accesos.some(a => a.name === 'MRF'),
              subOptions: [],
            },
            {
              title: "Formas",
              icon: BubbleChartOutlinedIcon,
              link: "/especies-vegetales/formas/paginador",
              component: <MantenedorFormasPage />,
              protected: true,
              hide: !accesos.some(a => a.name === 'FRM'),
              subOptions: [],
            },
            {
              title: "Elevaciones",
              icon: BubbleChartOutlinedIcon,
              link: "/especies-vegetales/elevaciones/paginador",
              component: <MantenedorElevacionesPage />,
              protected: true,
              hide: !accesos.some(a => a.name === 'ELV'),
              subOptions: [],
            },
            {
              title: "Superficies",
              icon: BubbleChartOutlinedIcon,
              link: "/especies-vegetales/superficies/paginador",
              component: <MantenedorSuperficiesPage />,
              protected: true,
              hide: !accesos.some(a => a.name === 'SUP'),
              subOptions: [],
            },
            {
              title: "Localidad",
              icon: BubbleChartOutlinedIcon,
              link: "/especies-vegetales/localidad/paginador",
              component: <MantenedorLocalidadesPage />,
              protected: true,
              hide: !accesos.some(a => a.name === 'LOC'),
              subOptions: [],
            },
            {
              title: "Medio de cultivo",
              icon: BubbleChartOutlinedIcon,
              link: "/especies-vegetales/medio-cultivo/paginador",
              component: <MantenedorMediosCultivosPage />,
              protected: true,
              hide: !accesos.some(a => a.name === 'MDC'),
              subOptions: [],
            },
            {
              title: "Tipo secuenciación",
              icon: BubbleChartOutlinedIcon,
              link: "/especies-vegetales/tipo-secuenciacion/paginador",
              component: <MantenedorTiposSecPage />,
              protected: true,
              hide: !accesos.some(a => a.name === 'TSC'),
              subOptions: [],
            },
          ],
        },
        {
          title: "Aislados",
          icon: BubbleChartOutlinedIcon,
          link: "/aislados",
          component: <GestorAisladosPage />,
          protected: true,
          hide: !accesos.some(a => a.name === 'AIS'),
          subOptions: [],
        },
        {
          title: "Publicaciones",
          icon: NewspaperOutlinedIcon,
          link: "/publicaciones",
          component: <GestorPublicacionesPage />,
          protected: true,
          hide: !accesos.some(a => a.name === 'PBL'),
          subOptions: [],
        },
      ]
    },
  ], [accesos]);

  const navegacion = useMemo(() => {
    return !auth.user 
      ? rutasPublicas
      : rutasPrivadas
  }, [auth.user, rutasPrivadas, rutasPublicas]);

  return (
    <>
      {!iniciaApp &&
        <div className="center">
          <div>
            <img src='assets/logos/4.png' style={{ height: 50 }} alt="Logo" />
          </div>
          <div style={{ marginTop: 10, marginBottom: 10, fontSize: "11px", fontStyle: "italic" }}>
            v{version}
          </div>
          <div className="meter">
            <span style={{ width: '100%' }}><span className="progress"></span></span>
          </div>
        </div>
      }
      {iniciaApp &&
        <ThemeProvider theme={theme}>
          <Navegacion
            /* Nombre de la aplicación (Título de toolbar y de página) */
            appName={appName}
            titulo={appName}
            /* Logo de la página */
            logo={<div style={{ backgroundImage: 'url("assets/logos/4.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', marginTop: "10px", height: "65px", width: "200px" }}></div>}
            /* Si la barra de navegación se encontrará a la izquierda o arriba */
            navSuperior={false}
            /* Barra navegación superior (toolbar) */
            topMenuProps={{
              backgroundColor: "rgba(255,255,255,0.8)",
              color: "black"
            }}
            /* Barra navegación superior (menú de opciones) */
            navSuperiorProps={{
              backgroundColor: "white",
              menuBgColor: "black",
              color: 'black',
              optionBgColor: 'black',
              optionColor: 'white',
            }}
            /* Barra navegación izquierda (menú de opciones) */
            leftMenuProps={{
              backgroundColor: "#efefef", //"#FEE4A9", //"#ECCB84",
              itemProps: { bgColor: "#FDBE31", color: "#242424" /*"#450700"*/ }, //{ bgColor: "#D7A230", color: "#450700" },
              subItemProps: { bgColor: "#FDBE31", color: "#242424" },//{ bgColor: "#D7A230", color: "#450700" },
              selectedItemProps: { bgColor: "#f7931e" /*"#FDBE31"*/, color: "white" },//{ bgColor: "#D7A230", color: "white" }
              loginButtonProps: { bgColor: "#747474", color: "white" },
            }}
            /* Footer */
            footer={{
              element: <CustomFooter 
                bgColor='#fafafa'
                subBgColor='#f7f7f7'
                titleColor='#2f348e'//'#476A6F'
                color="#2f348e"
              />,
              bgColor: "#d9d6ef",
            }}
            /* Páginas por defecto */
            loginPage={<LoginPage />}
            logoutPage={<LogoutPage />}
            notFoundPage={<ErrorPage />}
            errorPage={<ErrorPage />}
            /* Rutas de navegación */
            navegacion={navegacion}
          />
        </ThemeProvider>
      }
    </>
  );
}

export default App;
